<template>
  <div class="v-service crash">
    <div class="v-service-block area center">
        <div class="wrapper-notifications default">
            <p>Что-то пошло не так. Попробуйте обновить страницу, если ничего не выходит, то попробуйте позже</p>
        </div>
        <div class="v-service-img">
            <img src="@/assets/img/svg/service-crash.svg" alt="">
        </div>
        <div class="v-service-update-btn">
            <button
                class="btn-big btn-text-icon link-inline"
                @click="reloadPage"
            >
                <span class="btn-icon">
                            <svg>
                                <use xlink:href="@/assets/img/sprite.svg#search-icon"></use>
                            </svg>
                            <span class="btn-preload"></span>
                        </span>
                <span>Обновить страницу</span>
            </button>
        </div>
        <div class="v-service-home-btn">
            <router-link
                :to="{name: 'home'}"
                tag="button"
                class="btn-big btn-text primary"
            >
                <span>Вернуться на главную</span>
            </router-link>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'v-service-crash',
  methods: {
    reloadPage() {
        window.location.reload();
    }
  }
}
</script>

<style>

</style>